<template>
	<div class="login-view" ref="loginView" :style="backImgStyle">
    <login-model v-if="loginVisible" style="z-index: 1200" @cancel="cancelLogin" @init="init"></login-model>
    <div class="site-item">
      <span class="site-name">時之記憶</span>
    </div>
    <div class="box" id="box">
      <div class="face"></div>
      <div class="face"></div>
      <div class="face"></div>
      <div class="face"></div>
    </div>
    <div id="clock">
      <p class="date">{{ clock.date }}</p>
      <p class="time">{{ clock.time }}</p>
    </div>
    <div class="printer" @click="getGuShi()" v-if="showMediaType === 'picture'">
      <printer :printerInfo="printerInfo">
        <template slot="paper" slot-scope="scope">
          <h3>
            {{ scope.content }}<span class="cursor">|</span>
          </h3>
        </template>
      </printer>
    </div>

    <div class="videoContainer" v-if="showMediaType === 'video'">
      <video class="fullscreenVideo"
             ref="videoPlayer"
             id="videoPlayer"
             :src="videoUrl"
             autoplay="autoplay"
             muted
             type="video/mp4">
      </video>
      <div class="player unsound" @click="playVideo()">
      </div>
    </div>

    <div class="audioContainer" v-if="showMediaType === 'audio'" :style="audioBackImgStyle">
      <div ref="waveform_Ref" class="waveformRef"></div>
      <div class="playOrPause play-audio" @click="playMusic">
      </div>
      <div class="song-name">{{songName}}</div>
    </div>

    <div class="chat-container" v-show="showChatBox">

    </div>

    <div class="media-type" v-if="mediaInfo.showMedia">
<!--      <div class="user-avatar">
        <el-avatar size="medium" v-if="$store.state.userStore.userInfo.id" :src="$store.state.userStore.userInfo.headImageThumb" class="user-avatar" ></el-avatar>
        <el-avatar size="medium" v-else icon="el-icon-user-solid" class="user-avatar" @click.native="openLoginBox()"></el-avatar>
      </div>-->
      <div class="video-media" @click="getMediaByType('video')" :style="videoStyle">
        <img src="../assets/image/video.png">
      </div>
      <div class="audio-media" @click="getMediaByType('audio')" :style="audioStyle">
        <img src="../assets/image/audio.png">
      </div>
      <div class="picture-media" @click="getMediaByType('picture')" :style="pictureStyle">
        <img src="../assets/image/picture.png">
      </div>
      <div class="chat-bot" @click="toImWebSite()">
        <img src="../assets/image/chatbot_bubble.png">
      </div>
    </div>
    <div class="footer-wrap">
      <a href="https://beian.miit.gov.cn/" target="_blank">
        桂ICP备2022005991号-2
      </a>
    </div>
  </div>
</template>

<script>
import printer from "@/components/common/printer";
import WaveSurfer from "wavesurfer.js";
import * as THREE from "three";
import BIRDS from "vanta/src/vanta.birds";
import LoginModel from "@/components/common/LoginModel";

export default {
		name: "index",
    components: {
      printer,
      LoginModel,
    },
		data() {
			return {
        codeUrl: '',
        mediaInfo: {},
        showMediaType: null,
        curMediaType: null,
        isMuted: true,
        silence: true,
        videoUrl: '',
        backImgStyle: {
          backgroundImage: "url(" + require("@/assets/image/forest.jpeg") + ")",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          position: "relative",
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          height: "100%",
        },
        audioBackImgStyle: {
          position: "fixed",
          backgroundSize: "100% 100%",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          zIndex: "55",
          //backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundImage: "url(" + require("@/assets/image/background-image.jpg") + ")"
        },
        wavesurfer: null,
        playing: false,
        isPlayOver: false,
        songName: '',
        audioBtn: null,
        printerInfo: "你看对面的青山多漂亮",
        guShi: {
          "content": "",
          "origin": "",
          "author": "",
          "category": ""
        },
        videoStyle: {
          opacity: "0.5"
        },
        audioStyle: {
          opacity: "0.5"
        },
        pictureStyle: {
          opacity: "0.5"
        },
        clock: {
          time: '',
          date: ''
        },
        week: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        showChatBox: false,
        loginVisible: false,
			}
		},
    created() {
      this.getPlayMediaMaterial();
    },
    methods: {
      playMusic() {
        //this.wavesurfer.play();
        this.audioBtn=document.querySelector(".playOrPause");
        if (!this.playing) {
          this.audioBtn.classList.remove("play-audio");
          this.audioBtn.classList.add("pause-audio");
        } else {
          this.audioBtn.classList.remove("pause-audio");
          this.audioBtn.classList.add("play-audio");
        }
        this.wavesurfer.playPause.bind(this.wavesurfer)();
        this.playing = !this.playing;
        this.isPlayOver = false;
      },
      getPlayMediaMaterial() {
        this.mediaInfo.type = this.curMediaType;
        this.$http({
          url: "/home/getPlayMediaMaterial",
          method: "post",
          data: this.mediaInfo
        }).then((data) => {
          this.mediaInfo = data;
          if (data.showMedia) {
            if (data.type === 'video') {
              this.wavesurfer = null;
              this.showMediaType = 'video';
              this.videoUrl = data.url;
              this.addEndedEvent();
            } else if (data.type === 'picture') {
              this.wavesurfer = null;
              this.videoUrl = '';
              this.showMediaType = 'picture';
              this.backImgStyle.backgroundImage = "url(" + data.url + ")";
              setTimeout(this.getPlayMediaMaterial, data.displayDuration * 1000)

              this.getGuShi();
            } else if (data.type === "audio") {
              this.videoUrl = '';
              this.showMediaType = 'audio';
              this.audioBackImgStyle.backgroundImage = "url(" + data.coverImage + ")";
              this.songName = data.title;
              this.createAudioWaveSurfer(data.url);
            }
          } else {
            this.videoUrl = '';
          }
        })
      },
      getGuShi() {
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('get', 'https://v1.jinrishici.com/all.json');
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            that.guShi = JSON.parse(xhr.responseText);
            that.printerInfo = that.guShi.content;
          }
        };
        xhr.send();
      },
      getMediaByType(mediaType) {
        //console.log("mediaType", mediaType)
        if (mediaType==='video') {
          this.videoStyle.opacity = this.curMediaType === 'video' ? "0.5" : '1';
          this.audioStyle.opacity = "0.5";
          this.pictureStyle.opacity = "0.5";
          this.mediaInfo.type = this.curMediaType === 'video' ? null : mediaType;
        } else if (mediaType==='audio') {
          this.videoStyle.opacity = "0.5";
          this.audioStyle.opacity = this.curMediaType === 'audio' ? "0.5" : '1';
          this.pictureStyle.opacity = "0.5";
          this.mediaInfo.type = this.curMediaType === 'audio' ? null : mediaType;
        } else if (mediaType==='picture') {
          this.videoStyle.opacity = "0.5";
          this.audioStyle.opacity = "0.5";
          this.pictureStyle.opacity = this.curMediaType === 'picture' ? "0.5" : '1';
          this.mediaInfo.type = this.curMediaType === 'picture' ? null : mediaType;
        }
        this.curMediaType = this.mediaInfo.type;
      },
      addEndedEvent() {
        this.$nextTick(function () {
          let targetEle = document.getElementById("videoPlayer")
          targetEle.addEventListener('ended', this.getPlayMediaMaterial, false)
        });
      },
      playVideo() {
        let video=document.querySelector("video");
        let btn=document.querySelector(".player");
        if(video.muted){
          video.muted=false;/*不沉默*/
          btn.classList.remove("unsound");/*为btn元素移除unmute这个class name，即close图片消失*/
          btn.classList.add("sound");/*为btn元素添加mute这个class name，即open图片出现*/
        } else{
          video.muted=true;/*沉默*/
          btn.classList.remove("sound");/*open图片消失*/
          btn.classList.add("unsound");/*close图片出现*/
        }
      },
      createAudioWaveSurfer(url) {
        if (this.wavesurfer == null) {
          this.$nextTick(() => {
            this.wavesurfer = WaveSurfer.create({
              // 波形图的容器
              container: this.$refs.waveform_Ref,
              // 已播放波形的颜色
              //progressColor: "red",
              // 未播放波形的颜色
              waveColor: "lightgrey",
              // 波形图的高度，单位为px
              height: 1000,
              // 是否显示滚动条，默认为false
              scrollParent: false,
              // 波形的振幅（高度），默认为1
              barHeight: 0.8,
              // 波形条的圆角
              barRadius: 2,
              // 波形条的宽度
              barWidth: 1,
              // 波形条间的间距
              barGap: 3,
              // 播放进度光标条的颜色
              cursorColor: "red",
              // 播放进度光标条的宽度，默认为1
              cursorWidth: 4,
              // 播放进度颜色
              progressColor: "blue",
              // 波形容器的背景颜色
              //backgroundColor: "yellow",
              // 音频的播放速度
              audioRate: "1",
              // （与区域插件一起使用）启用所选区域的循环
              // loopSelection:false
            });
            this.wavesurfer.on('finish', this.audioPlayOver);
            this.wavesurfer.load(url);
          })
        } else {
          this.wavesurfer.on("ready", this.playMusic);
          this.wavesurfer.load(url);
        }
      },
      audioPlayOver() {
        //console.log("播放完成")
        if (!this.isPlayOver) {
          this.isPlayOver = true;
          this.playing = !this.playing;
          this.audioBtn.classList.remove("pause-audio");
          this.audioBtn.classList.add("play-audio");
          // 重置波形
          this.wavesurfer.stop();
        }
        this.getPlayMediaMaterial();
      },
      updateTime() {
        let cd = new Date();
        this.clock.time = this.zeroPadding(cd.getHours(), 2)
            + ':' + this.zeroPadding(cd.getMinutes(), 2)
            + ':' + this.zeroPadding(cd.getSeconds(), 2)
            + '.' + this.zeroPadding(cd.getMilliseconds(), 3);
        this.clock.date = this.zeroPadding(cd.getFullYear(), 4)
            + '-' + this.zeroPadding(cd.getMonth()+1, 2)
            + '-' + this.zeroPadding(cd.getDate(), 2)
            + ' ' + this.week[cd.getDay()];
      },
      zeroPadding(num, digit) {
        let zero = '';
        for(let i = 0; i < digit; i++) {
          zero += '0';
        }
        return (zero + num).slice(-digit);
      },
      toImWebSite() {
        window.open("https://im.timemory.xyz",'_blank')
      },
      doShowChatBox() {
        if (this.$store.state.userStore.userInfo.id) {
          this.showChatBox = !this.showChatBox;
        } else {
          this.$message.warning("请先登录");
        }
      },
      openLoginBox() {
        this.loginVisible = true;
      },
      cancelLogin() {
        this.loginVisible = false;
      },
      init() {
        this.$store.dispatch("load").then(() => {
          // ws初始化
          this.$wsApi.connect(process.env.VUE_APP_WS_URL, sessionStorage.getItem("accessToken"));
          this.$wsApi.onConnect(()=>{
            // 加载离线消息
            //this.loadPrivateMessage(this.$store.state.chatStore.privateMsgMaxId);
            //this.loadGroupMessage(this.$store.state.chatStore.groupMsgMaxId);
          });
          this.$wsApi.onMessage((cmd, msgInfo) => {
            if (cmd == 2) {
              // 关闭ws
              this.$wsApi.close(3000)
              // 异地登录，强制下线
              this.$alert("您已在其他地方登陆，将被强制下线", "强制下线通知", {
                confirmButtonText: '确定',
                callback: action => {
                  location.href = "/";
                }
              });

            } else if (cmd == 3) {
              // 插入私聊消息
              //this.handlePrivateMessage(msgInfo);
            } else if (cmd == 4) {
              // 插入群聊消息
              //this.handleGroupMessage(msgInfo);
            }
          });
          this.$wsApi.onClose((e) => {
            //console.log(e);
            if (e.code != 3000) {
              // 断线重连
              this.$message.error("连接断开，正在尝试重新连接...");
              this.$wsApi.reconnect(process.env.VUE_APP_WS_URL, sessionStorage.getItem("accessToken"));
            }
          });
        }).catch((e) => {
          console.log("初始化失败", e);
        })
      },
		},
		mounted() {
      const timer = setInterval(this.updateTime, 1);
      this.$once('hook:beforeDestroy', () => {
        console.log('clearInterval done')
        clearInterval(timer);
      })

		  this.vantaEffect = BIRDS({
        el: this.$refs.loginView,
        THREE: THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.0,
        minWidth: 200.0,
        scale: 1.0,
        scaleMobile: 1.0,
        backgroundAlpha: 0.1,
        color1: 0x1a6138,
        color2: 0xf6f7f7,
      })
      // 修改颜色时 cells 需要全大写字母 可生效
		},
    computed: {

    },
    beforeDestroy() {
      if (this.vantaEffect) {
        this.vantaEffect.destroy();
      }
    },
	}
</script>

<style scoped lang="scss">
	.login-view {
    @keyframes hue {
      0% {
        filter: hue-rotate(0deg);
      }
      100% {
        filter: hue-rotate(360deg);
      }
    }

    .site-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      top: 10px;
      right: 10px;
      z-index: 999;
      position: absolute;

      .site-name {
        margin-top: 5px;
        color: #F0F8FF;
        writing-mode: vertical-rl;
        display: inline-block;
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        /*background: linear-gradient(to bottom, red, orange, yellow, green, cyan, blue,  purple);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation:  hue 3s linear infinite;*/
      }
    }


    @keyframes rotate3d {
      from {
        transform: rotate3d(1, 1, 1, 0deg)
      }

      to {
        transform: rotate3d(1, 1, 1, 360deg)
      }

    }

    .box,
    .face {
      width: 120px;
      height: 100px
    }

    .box {
      position: absolute;
      margin: 50px auto;
      transform-origin: 50% 66%;
      transform-style: preserve-3d;
      animation: rotate3d 10s linear infinite;
      z-index: 999;

      .face {
        position: absolute;
        background: rgba(255, 255, 255, .2);
        -webkit-clip-path: polygon(50%0, 0 100%, 100%100%);
        -moz-clip-path: polygon(50%0, 0 100%, 100%100%);
        clip-path: polygon(50%0, 0 100%, 100%100%);
      }

      .face:first-child {
        transform: rotate3d(-1, 1.732, 0, 70.5deg);
        transform-origin: 25% 50%;
        background: rgba(120, 255, 255, .2);
      }

      .face:nth-child(2) {
        transform: rotate3d(1, 1.732, 0, -70.5deg);
        transform-origin: 75% 50%;
        background: rgba(255, 255, 120, .2);
      }

      .face:nth-child(3) {
        transform: rotate3d(1, 0, 0, 70.5deg);
        transform-origin: 50% 100%;
        background: rgba(255, 120, 255, .2);
      }
    }

    #clock {
      font-family: "Share Tech Mono", monospace;
      color: #ffffff;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-shadow: 0 0 20px #0aafe6, 0 0 20px rgba(10, 175, 230, 0);
      z-index: 999;
    }

    #clock .time {
      letter-spacing: 0.05em;
      font-size: 80px;
      padding: 5px 0;
    }

    #clock .date {
      letter-spacing: 0.1em;
      font-size: 24px;
    }

    .printer {
      position: absolute;
      cursor: pointer;
      color: white;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      padding: 10px;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%); /* 移动元素本身50% */
      z-index: 60;
    }

    .videoContainer{
      position: fixed;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 50;

      .fullscreenVideo {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }

      .unsound{
        background-image:url("../assets/image/close.png");
      }

      .sound{
        background-image:url("../assets/image/open.png");
      }

      .player{
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 36px;
        height: 36px;
        cursor: pointer;
        bottom: 20px;
        left: 15px;
        z-index: 60;
      }
    }

    .videoContainer:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 50;
      top: 0;
      left: 0;
    }

    .audioContainer {

      .waveformRef {
        width: 100%;
        height: 100%;
        white-space: nowrap;
      }

      .playOrPause {
        width: 36px;
        height: 36px;
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 20px;
        left: 15px;
        z-index: 60;
        cursor:pointer;
      }

      .play-audio {
        background-image: url("../assets/image/play.png");
      }

      .pause-audio {
        background-image: url("../assets/image/pause.png");
      }

      .song-name {
        position: absolute;
        bottom: 20px;
        z-index: 60;
        margin: 0 auto;
        font-size: xx-large;
        left: 50%;
        transform: translateX(-50%); /* 移动元素本身50% */
        text-shadow: 0 0 5px #fff,
        0 0 10px #fff,
        0 0 15px #fff,
        0 0 20px #00a67c,
        0 0 35px #00a67c,
        0 0 40px #00a67c,
        0 0 50px #00a67c,
        0 0 75px #00a67c;
      }
    }

    .chat-container {
      position: absolute;
      width: 90%;
      height: 90%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      overflow: hidden;
      z-index: 1200;
      background-color: #6CC6CB;
    }

    @-webkit-keyframes dock-ripple {
      from {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 250, 0, 0.95);
        box-shadow: 0 0 0 0 rgba(0, 250, 0, 0.95);
      }

      to {
        -webkit-box-shadow: 0 0 0 15px rgba(0, 250, 0, 0);
        box-shadow: 0 0 0 15px rgba(0, 250, 0, 0);
      }

    }

    @keyframes dock-ripple {
      from {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 250, 0, 0.95);
        box-shadow: 0 0 0 0 rgba(0, 250, 0, 0.95);
      }

      to {
        -webkit-box-shadow: 0 0 0 15px rgba(0, 250, 0, 0);
        box-shadow: 0 0 0 15px rgba(0, 250, 0, 0);
      }
    }

    .media-type {
      position: absolute;
      right: 20px;
      bottom: 20px;
      z-index: 105;

      div {
         img {
           width: 36px;
           height: 36px;
         }
        margin-top: 10px;
        cursor:pointer;
      }

      .video-media {

      }

      .audio-media {

      }

      .picture-media {

      }

      .chat-bot {
        height: 32px;
        border-radius: 50%;
        -webkit-animation: dock-ripple 1s ease-in-out infinite;
        animation: dock-ripple 1s ease-in-out infinite;

        img {
          overflow: hidden;
        }
      }
    }

    .footer-wrap {
      z-index: 999;
      color: #cccccc;
      font-size: 14px;
      position: absolute;
      bottom: 5px;

      a, a:visited, a:link, a:hover{
        text-decoration: none;
        color:white;
      }
    }
  }

	
</style>
