<template>
  <div width="20%" class="login-box">
    <div  class="btn-cancel el-icon-error" @click.stop="cancelLogin"></div>
    <el-form :model="loginForm"  status-icon :rules="rules" ref="loginForm"  label-width="60px" class="web-ruleForm"
             @keyup.enter.native="submitForm('loginForm')">
      <div class="login-brand">欢迎登录</div>
      <el-form-item label="用户名" prop="username">
        <el-input type="username" v-model="loginForm.userName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="loginForm.password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input
            v-model="loginForm.code"
            auto-complete="off"
            placeholder="验证码"
            style="width: 120px"
        >
        </el-input>
        <div class="login-code">
          <img :src="codeUrl" @click="getCode" class="login-code-img"/>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('loginForm')">登陆</el-button>
        <el-button @click="resetForm('loginForm')">清空</el-button>
      </el-form-item>
      <div class="register-box">
        <div class="register" @click="toRegister()">立即注册</div>
        <div class="forget-pwd">忘记密码</div>
        <p style="clear:both;"></p>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "LoginModel",
  components: {

  },
  props: {
    visible: {
      type: Boolean
    },
  },
  data() {
    let pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
    let reg =/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/gi;
    let checkUsername = (rule, value, callback) => {
      //console.log("checkUsername");
      if (!value) {
        return callback(new Error('请输入用户名'));
      }
      if (pattern.test(value)) {
        callback(new Error('不允许输入特殊字符'));
      }
      if (reg.test(value)) {
        callback(new Error('用户名不能有中文'));
      }
      callback();
    };
    let checkPassword = (rule, value, callback) => {
      //console.log("checkPassword");
      if (value === '') {
        callback(new Error('请输入密码'));
      }
      callback();
    };
    return {
      codeUrl: '',
      loginForm: {
        terminal: this.$enums.TERMINAL_TYPE.WEB,
        userName: '',
        password: '',
        code: '',
        uuid: ''
      },
      rules: {
        userName: [{
          validator: checkUsername,
          trigger: 'blur'
        }],
        password: [{
          validator: checkPassword,
          trigger: 'blur'
        }],
        code: [
          { required: true, trigger: "change", message: "请输入验证码" },
          { min: 1, max: 4, message: "请输入4位验证码", trigger: "change"}
        ]
      },
    }
  },
  created() {
    this.getCode();
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http({
            url: "/login",
            method: 'post',
            data: this.loginForm
          }).then((data) => {
              // 保存密码到cookie(不安全)
              this.setCookie('username', this.loginForm.userName);
              this.setCookie('password', this.loginForm.password);
              // 保存token
              sessionStorage.setItem("accessToken", data.accessToken);
              sessionStorage.setItem("refreshToken", data.refreshToken);
              this.$message.success("登陆成功");
              this.$emit("init");
              this.$emit("cancel");
          })
        }
      });
    },
    setCookie (name, value, expiredays) {
      let exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie = name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
    },
    getCode() {
      this.$http({
        url: "captchaImage",
        method: "get"
      }).then((result) => {
        this.codeUrl = "data:image/gif;base64," + result['img'];
        this.loginForm.uuid = result['uuid'];
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    toRegister() {
      this.$router.push("/register");
    },
    cancelLogin() {
      this.$emit("cancel");
    }
  }
}
</script>

<style lang="scss">
.login-box {
  @keyframes hue {
    0% {
      filter: hue-rotate(0deg);
    }
    100% {
      filter: hue-rotate(360deg);
    }
  }
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  .btn-cancel {
    z-index: 1200;
    display: block;
    position: absolute;
    right: -8px;
    top: -8px;
    color: darkred;
    font-size: 20px;
    cursor: pointer;
  }

  .web-ruleForm {
    height: 400px;
    padding: 20px;
    background: rgba(255, 255, 255, .75);
    box-shadow: 0px 0px 1px #ccc;
    border-radius: 5px;
    overflow: hidden;
    z-index: 1200;

    .login-brand {
      line-height: 30px;
      margin: 10px 0 20px 0;
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
      text-align: center;
      background: linear-gradient(to right, red, orange, yellow, green, cyan, blue,  purple);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation:  hue 3s linear infinite;
    }

    .login-code {
      width: 90px;
      height: 38px;
      float: right;
      margin-right: 25px;
      margin-left: 10px;
      img {
        cursor: pointer;
        vertical-align: middle;
      }

      .login-code-img {
        height: 38px;
      }
    }

    .register-box {
      line-height: 40px;

      .register {
        float: left;
        cursor:pointer;
      }

      .forget-pwd {
        float: right;
        cursor:pointer;
      }
    }
  }
}
</style>